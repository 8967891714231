import React, { useEffect, useState, useContext } from "react"
import i18n from "i18next"
import { Link } from "gatsby"
import NavBarMobile from "./NavBarMobile"
import NavItemService from "./NavItemService"
import NavItem from "./NavItem"
import Button from "./ButtonLanguage"
import ButtonGetInTouch from "./ButtonGetInTouch"
import ExsisLogo from "../../../images/navBar/LogocolorExsis.jpg"
import ExsisBirthday from "../../../images/navBar/Logo29years.png"
import { LoadingContext } from "../../context/LoadingContext"

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const NavBar = () => {
  const [loadMobile, setLoadMobile] = useState(null)
  const { windowWidth } = useContext(LoadingContext)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const [anchorElMedia, setAnchorElMedia] = React.useState(null)
  const openMedia = Boolean(anchorElMedia)

  const [pathname, setPathname] = useState()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickMedia = event => {
    setAnchorElMedia(event.currentTarget)
  }

  useEffect(() => {
    windowWidth < 1078 ? setLoadMobile(true) : setLoadMobile(false)
  }, [windowWidth])

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  function closeNavbar() {
    setAnchorEl(null)
  }

  function closeMedia() {
    setAnchorElMedia(null)
  }

  return (
    <>
      <NavBarMobile pathname={pathname} />
      <header className="header-navbar navBarDesktop">
        <div className="logoExsis ml-20">
          <Link to={"/"}>
            <img className="" src={ExsisBirthday} alt="Exsis logo" />
          </Link>
        </div>
        <div className="header-navbar__right-side navar-nodo ml-12">
          <nav
            className="flex justify-center pb-1 pt-1"
            style={{ alignSelf: "center" }}
          >
            <div className="mt-auto mb-auto cursor-pointer">
              <Link to={"/"}>
                <p
                  className={
                    pathname == "/" ? "titlerSelectMenu" : "titleSectionNavBar"
                  }
                >
                  {i18n.t("home.menu.seccion1")}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <div className="flex" onClick={e => handleClick(e)}>
                {i18n.language == "en" ? (
                  <>
                    <p
                      className={
                        pathname == "/our-Experience" ||
                        pathname == "/exsis-academy"
                          ? "titleSectionNavBar2Select"
                          : "titleSectionNavBar2"
                      }
                    >
                      E
                    </p>
                    <p
                      className={
                        pathname == "/our-Experience" ||
                        pathname == "/exsis-academy"
                          ? "titleSectionNavBarBlueSelect"
                          : "titleSectionNavBarBlue"
                      }
                    >
                      x
                    </p>
                    <p
                      className={
                        pathname == "/our-Experience" ||
                        pathname == "/exsis-academy"
                          ? "titleSectionNavBar2Select"
                          : "titleSectionNavBar2"
                      }
                    >
                      perience{" "}
                    </p>
                  </>
                ) : (
                  <>
                    <p
                      className={
                        pathname == "/our-Experience" ||
                        pathname == "/exsis-academy"
                          ? "titleSectionNavBar2Select"
                          : "titleSectionNavBar2"
                      }
                    >
                      E
                    </p>
                    <p className="titleSectionNavBarBlue">x</p>
                    <p
                      className={
                        pathname == "/our-Experience" ||
                        pathname == "/exsis-academy"
                          ? "titleSectionNavBar2Select"
                          : "titleSectionNavBar2"
                      }
                    >
                      periencia{" "}
                    </p>
                  </>
                )}
              </div>

              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={closeNavbar}
              >
                <Link to={"/our-Experience"}>
                  <MenuItem onClick={closeNavbar}>
                    <p className="titleSectionNavBar cursor-pointer">
                      {i18n.t("home.menu.seccion8")}
                    </p>
                  </MenuItem>
                </Link>
                <Link to={"/exsis-academy"}>
                  <MenuItem onClick={closeNavbar}>
                    <p className="titleSectionNavBar cursor-pointer">
                      {i18n.t("home.menu.seccion9")}
                    </p>
                  </MenuItem>
                </Link>
              </Menu>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <Link to={"/service-expansion"}>
                <p
                  className={
                    pathname == "/service-expansion" ||
                    pathname == "/services-software" ||
                    pathname == "/services-hunting" ||
                    pathname == "/services-blockchain"
                      ? "titlerSelectMenu"
                      : "titleSectionNavBar"
                  }
                >
                  {i18n.t("home.menu.seccion2")}{" "}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <Link to={"/exclusive-products"}>
                <p
                  className={
                    pathname == "/exclusive-products" ||
                    pathname == "/TalentATS" ||
                    pathname == "/agile-team" ||
                    pathname == "/transformation"
                      ? "titlerSelectMenu"
                      : "titleSectionNavBar"
                  }
                >
                  {i18n.t("home.menu.seccion3")}{" "}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto cursor-pointer">
              <Link to={"/exceptional-careers"}>
                <p
                  className={
                    pathname == "/exceptional-careers"
                      ? "titlerSelectMenu"
                      : "titleSectionNavBar"
                  }
                >
                  {i18n.t("home.menu.seccion4")}{" "}
                </p>
              </Link>
            </div>

            <div className="ml-8 mt-auto mb-auto flex">
              <div
                className="flex cursor-pointer"
                onClick={e => handleClickMedia(e)}
              >
                <p
                  className={
                    pathname == "/media-blog" ||
                    pathname == "/x-media-success-stories" ||
                    pathname == "/x-media-podcast"
                      ? "titleSectionNavBarBlueSelect"
                      : "titleSectionNavBarBlue"
                  }
                >
                  X
                </p>
                <p
                  className={
                    pathname == "/media-blog" ||
                    pathname == "/x-media-success-stories" ||
                    pathname == "/x-media-podcast"
                      ? "titleSectionNavBar2Select ml-2"
                      : "titleSectionNavBar2 ml-2"
                  }
                >
                  Media{" "}
                </p>
              </div>

              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorElMedia}
                open={openMedia}
                onClose={closeMedia}
              >
                <Link to={"/media-blog"}>
                  <MenuItem onClick={closeMedia}>
                    <p className="titleSectionNavBar cursor-pointer">
                      {i18n.t("home.menu.seccion5")}
                    </p>
                  </MenuItem>
                </Link>
                <Link to={"/x-media-success-stories"}>
                  <MenuItem onClick={closeMedia}>
                    <p className="titleSectionNavBar cursor-pointer">
                      {i18n.t("home.menu.seccion6")}
                    </p>
                  </MenuItem>
                </Link>
                {i18n.language == "es" ? (
                  <Link to={"/x-media-podcast"}>
                    <MenuItem onClick={closeMedia}>
                      <p className="titleSectionNavBar cursor-pointer">
                        {i18n.t("home.menu.seccion7")}
                      </p>
                    </MenuItem>
                  </Link>
                ) : null}
              </Menu>
            </div>

            <ButtonGetInTouch />
            <Button />
          </nav>
        </div>
      </header>
    </>
  )
}

export default NavBar
